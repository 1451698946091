import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  FormField,
  Textarea,
  Input,
  ColumnLayout,
} from '@amzn/awsui-components-react/polaris';
import { useState, useEffect } from 'react';

import {
  LaunchTask,
  WorkStatus,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';

interface LaunchTaskProps {
  task?: LaunchTask;
}

export const WriterSection = ({ task }: LaunchTaskProps) => {
  const [writerAlias, setWriterAlias] = useState<string | undefined>(
    task?.writerAssignment?.alias || undefined
  );

  const [writerFullName, setWriterFullName] = useState<string | undefined>(
    task?.writerAssignment?.fullName || undefined
  );

  // useEffect(() => {
  //   setWriterAlias(task?.writerAssignment?.alias);
  //   setWriterFullName(task?.writerAssignment?.fullName);
  // }, [task]);

  return (
    <div>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween size={'m'} direction={'horizontal'}>
                <Button
                  onClick={() =>
                    (location.href = `/#/conferences/view-${task?.launchId}/permissions`)
                  }
                  disabled={!task}
                >
                  Grant permissions
                </Button>
                <Button
                  variant='primary'
                  disabled={task?.workStatus === WorkStatus.DELETED}
                  onClick={() => {
                    location.href = `/#/conferences/view-${task?.launchId.toString()}/writer`;
                  }}
                >
                  Edit
                </Button>
              </SpaceBetween>
            }
          >
            Assigned writer
          </Header>
        }
      >
        <ColumnLayout columns={2}>
          <div className='editorViewColumn1'>
            <FormField label={'Writer'} />
            <Input
              disabled={true}
              value={task?.writerAssignment?.alias ?? '-'}
            />
            <FormField label={'Documentation Status'} />
            <Input disabled={true} value={task?.documentationStatus ?? '-'} />
          </div>
          <div className='editorViewColumn3'>
            <FormField label={'Documentation Status Notes'} />
            <SpaceBetween direction='vertical' size='m'>
              <Textarea
                disabled={true}
                value={task?.documentationNotes ?? ''}
              />
            </SpaceBetween>
          </div>
        </ColumnLayout>
        {/* <FormField
          // description='alias of the assigned writer. TLC automatically assigns are writer based on data in virtual smiley. If the information below is incorrect, please reassign to the correct dependencies.'
          label='Assigned Writer Alias'
        >
          <Input disabled={true} value={writerAlias ? writerAlias : '-'} />
        </FormField> */}
      </Container>
    </div>
  );
};

export default WriterSection;
