import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  Table,
  Pagination,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { useState, useEffect, useContext } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useAuthState } from '../../../authentication/context';
import { generateFlashBarItem } from './editEditorViewSection';
import { callApi } from '../../../client';
import {
  GetPermissionsOutput,
  UpdatePermissionsInput,
  GetPermissionsCommand,
  GetPermissionsCommandOutput,
  UpdatePermissionsCommand,
  UpdatePermissionsCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { PermissionsContext } from './permissionscontext';
import { NoTokenError } from '../../../errors/NoTokenError';

export const PermissionsView = ({ id }: { id: string }) => {
  const { isAuthenticated, token } = useAuthState();

  const {
    readPermissions,
    writePermissions,
    updateReadPermissions,
    updateWritePermissions,
    notifications,
    setNotifications,
  } = useContext(PermissionsContext);

  const permissionsId = 'updatePermissionsMsg';
  const permissionsItem = generateFlashBarItem(permissionsId);
  permissionsItem.onDismiss = () =>
    setNotifications((items) =>
      items.filter((item) => item.id !== 'updatePermissionsMsg')
    );
  permissionsItem.type = 'success';
  permissionsItem.content = 'removing permissions';
  permissionsItem.loading = true;

  const [displayPermissionsFlashBar, setDisplayPermissionsFlashBar] =
    useState(false);
  const [
    isCallUpdatePermissionsApiLoading,
    setIsCallUpdatePermissionsApiLoading,
  ] = useState(true);
  const [
    isCallUpdatePermissionsApiSuccess,
    setIsCallUpdatePermissionsApiSuccess,
  ] = useState(true);
  const [
    callUpdatePermissionsApiStatusCode,
    setCallUpdatePermissionsApiStatusCode,
  ] = useState(-1);
  const [callUpdatePermissionsApiErrMsg, setCallUpdatePermissionsApiErrMsg] =
    useState('');

  async function callGetReadPermissionsApi(id: string) {
    if (!token) throw new NoTokenError();
    try {
      const response: GetPermissionsCommandOutput = await callApi(
        new GetPermissionsCommand({
          launchId: id,
        }),
        token
      );
      updateReadPermissions(response.readAcl);
    } catch (err) {
      console.log('error with GET Permissions', err);
    }
  }

  async function callGetWritePermissionsApi(id: string) {
    if (!token) throw new NoTokenError();
    try {
      const response: GetPermissionsCommandOutput = await callApi(
        new GetPermissionsCommand({
          launchId: id,
        }),
        token
      );
      updateWritePermissions(response.writeAcl);
    } catch (err) {
      console.log('error with GET Permissions', err);
    }
  }

  async function callUpdatePermissionsApi(body: UpdatePermissionsInput) {
    if (!token) {
      setIsCallUpdatePermissionsApiSuccess(false);
      throw new NoTokenError();
    }

    try {
      const response: UpdatePermissionsCommandOutput = await callApi(
        new UpdatePermissionsCommand({
          launchId: id,
          readAcl: body.readAcl,
          writeAcl: body.writeAcl,
        }),
        token
      );
      if (response.$metadata.httpStatusCode == 200) {
        setIsCallUpdatePermissionsApiSuccess(true);
        updateReadPermissions(body.readAcl);
        updateWritePermissions(body.writeAcl);
        setSelectedItems([]);
      }
    } catch (err: any) {
      setIsCallUpdatePermissionsApiSuccess(false);
      setCallUpdatePermissionsApiErrMsg(err.message);
      setCallUpdatePermissionsApiStatusCode(err.statusCode);
    }
  }

  interface Permissions {
    name: string;
    permission: ('read' | 'write')[];
  }

  const [allItems, setItems] = useState<Permissions[]>([]);
  useEffect(() => {
    if (isCallUpdatePermissionsApiLoading) {
      permissionsItem.loading = true;
    } else {
      permissionsItem.loading = false;

      if (!isCallUpdatePermissionsApiSuccess) {
        permissionsItem.type = 'error';
        permissionsItem.content = `error ${callUpdatePermissionsApiStatusCode}: failed to remove permission. ${callUpdatePermissionsApiErrMsg}`;
      } else {
        permissionsItem.type = 'success';
        permissionsItem.content = 'removing permissions succeed';
      }
    }
    if (displayPermissionsFlashBar) {
      setNotifications([
        ...notifications.filter((item) => item.id !== 'updatePermissionsMsg'),
        permissionsItem,
      ]);
    }
  }, [
    isCallUpdatePermissionsApiLoading,
    isCallUpdatePermissionsApiSuccess,
    callUpdatePermissionsApiErrMsg,
    callUpdatePermissionsApiStatusCode,
  ]);

  useEffect(() => {
    const permissions: Permissions[] = [];
    readPermissions.forEach((name) => {
      const permission: ('read' | 'write')[] = [];
      if (writePermissions.includes(name)) {
        permission.push('read', 'write');
      } else {
        permission.push('read');
      }
      permissions.push({ name, permission });
    });

    writePermissions.forEach((name) => {
      if (!readPermissions.includes(name)) {
        permissions.push({ name, permission: ['write'] });
      }
    });

    setItems(permissions);
  }, [readPermissions, writePermissions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await callGetReadPermissionsApi(id);
        await callGetWritePermissionsApi(id);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchData();
  }, [id]);

  const [selectedItems, setSelectedItems] = useState<Permissions[]>([]);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(allItems, {
      filtering: {
        filteringFunction: (item: Permissions, filteringText: string) =>
          item.name.toLowerCase().includes(filteringText.toLowerCase()),
        noMatch: (
          <Box margin={{ vertical: 'xl' }} textAlign='center' color='inherit'>
            <SpaceBetween size='xxl'>
              <SpaceBetween size='xs'>
                <div>
                  <b>No matches</b>
                  <Box variant='p' color='inherit'>
                    Try search again
                  </Box>
                </div>
                <Button
                  onClick={() => {
                    actions.setFiltering('');
                  }}
                >
                  Clear Filter
                </Button>
              </SpaceBetween>
            </SpaceBetween>
          </Box>
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
      selection: {},
    });

  return (
    <SpaceBetween direction='vertical' size='s'>
      <Container>
        <SpaceBetween size='m' direction='vertical'>
          <Table
            {...collectionProps}
            onSelectionChange={({ detail }) =>
              setSelectedItems(detail.selectedItems)
            }
            selectedItems={selectedItems}
            columnDefinitions={[
              {
                id: 'name',
                header: 'User alias or POSIX group',
                cell: (item: Permissions) => item.name,
                sortingField: 'name',
                isRowHeader: true,
              },
              {
                id: 'permissions',
                header: 'Permissions',
                cell: (item: Permissions) => item.permission.join(', '),
                sortingField: 'permissions',
                isRowHeader: true,
              },
            ]}
            items={items}
            loadingText='Loading resources'
            selectionType='multi'
            trackBy='name'
            header={
              <Header
                actions={
                  <SpaceBetween direction='horizontal' size='xs'>
                    {' '}
                    <Button
                      onClick={() => {
                        const filteredReadPermissions: string[] =
                          readPermissions.filter(
                            (permission) =>
                              !selectedItems.some(
                                (item) => item.name === permission
                              )
                          );

                        const body: UpdatePermissionsInput = {
                          launchId: id,
                          readAcl: filteredReadPermissions,
                          writeAcl: writePermissions,
                        };
                        setIsCallUpdatePermissionsApiLoading(true);
                        setDisplayPermissionsFlashBar(true);
                        callUpdatePermissionsApi(body);
                      }}
                    >
                      Remove read permissions
                    </Button>
                    <Button
                      onClick={() => {
                        const filteredWritePermissions: string[] =
                          writePermissions.filter(
                            (permission) =>
                              !selectedItems.some(
                                (item) => item.name === permission
                              )
                          );
                        const body: UpdatePermissionsInput = {
                          launchId: id,
                          readAcl: readPermissions,
                          writeAcl: filteredWritePermissions,
                        };
                        setIsCallUpdatePermissionsApiLoading(true);
                        setDisplayPermissionsFlashBar(true);
                        callUpdatePermissionsApi(body);
                      }}
                    >
                      Remove write permissions
                    </Button>
                    <Button
                      onClick={() => {
                        const filteredReadPermissions: string[] =
                          readPermissions.filter(
                            (permission) =>
                              !selectedItems.some(
                                (item) => item.name === permission
                              )
                          );
                        const filteredWritePermissions: string[] =
                          writePermissions.filter(
                            (permission) =>
                              !selectedItems.some(
                                (item) => item.name === permission
                              )
                          );
                        const body: UpdatePermissionsInput = {
                          launchId: id,
                          readAcl: filteredReadPermissions,
                          writeAcl: filteredWritePermissions,
                        };
                        setIsCallUpdatePermissionsApiLoading(true);
                        setDisplayPermissionsFlashBar(true);
                        callUpdatePermissionsApi(body);
                      }}
                    >
                      Remove All
                    </Button>
                  </SpaceBetween>
                }
                counter={
                  selectedItems.length
                    ? '(' + selectedItems.length + '/' + allItems.length + ')'
                    : '(' + allItems.length + ')'
                }
              >
                Table with Permissions
              </Header>
            }
            pagination={<Pagination {...paginationProps} />}
            filter={
              <TextFilter
                filteringPlaceholder='Find alias or Posix group'
                {...filterProps}
              />
            }
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
