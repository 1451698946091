import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  FormField,
  Autosuggest,
  Flashbar,
  FlashbarProps,
  Select,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import { useState, useEffect } from 'react';

import { TraslatedDocumentStatus } from '../../../interfaces/index';
import {
  LaunchTask,
  WorkStatus,
  DocumentationStatus,
  UpdateWriterAssignmentCommand,
  UpdateWriterAssignmentCommandOutput,
  UpdateWriterAssignmentCommandInput,
  UpdateDocumentationNotesCommand,
  UpdateDocumentationNotesCommandOutput,
  UpdateDocumentationStatusCommand,
  UpdateDocumentationStatusCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { OptionGroup } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useAuthState } from '../../../authentication/context';
import { callApi } from '../../../client';
import { NoTokenError } from '../../../errors/NoTokenError';

import { v4 } from 'uuid';
import { generateFlashBarItem } from '../ui/editEditorViewSection';

interface LaunchTaskProps {
  task: LaunchTask;
}

const options = [
  {
    label: TraslatedDocumentStatus[DocumentationStatus.GREEN].toString(),
    value: DocumentationStatus.GREEN,
  },
  {
    label: TraslatedDocumentStatus[DocumentationStatus.YELLOW].toString(),
    value: DocumentationStatus.YELLOW,
  },
  {
    label: TraslatedDocumentStatus[DocumentationStatus.RED].toString(),
    value: DocumentationStatus.RED,
  },
];

export const EditWriterSection = ({ task }: LaunchTaskProps) => {
  const [editWriterEnabled, setEditWriterEnabled] = useState<boolean>(false);
  const [writerAlias, setWriterAlias] = useState<string | undefined>(
    task?.writerAssignment?.alias || undefined
  );
  const [writerAliasCache, setwriterAliasCache] = useState<string | undefined>(
    task?.writerAssignment?.alias || undefined
  );

  const [writerFullName, setWriterFullName] = useState<string | undefined>(
    task?.writerAssignment?.fullName || undefined
  );
  const [writerFullNameCache, setwriterFullNameCache] = useState<
    string | undefined
  >(task?.writerAssignment?.fullName || undefined);

  const [autoSuggestions, setAutoSuggestions] = useState<OptionGroup[]>([]);

  const { isAuthenticated, token } = useAuthState();

  const [errorIndicator, setErrorIndicator] = useState('');

  const [displayFlashbarWriter, setDisplayFlashbarWriter] = useState(false);

  const [writerLoading, setWriterLoading] = useState(true);

  const [callUpdateWriterAPIResult, setCallUpdateWriterAPIResult] =
    useState(true);

  const [
    selectedDocumentationStatusString,
    setSelectedDocumentationStatusString,
  ] = useState({
    label: task?.documentationStatus
      ? TraslatedDocumentStatus[task?.documentationStatus].toString()
      : options[0].label,
    value: task?.documentationStatus
      ? task?.documentationStatus.toString()
      : options[0].value,
  });

  const [documentationNotes, setDocumentationNotes] = useState(
    task?.documentationNotes
  );

  const [
    callUpdateDocumentationNotesAPIResult,
    setCallUpdateDocumentationNotesAPIResult,
  ] = useState(true);
  const [
    callUpdateDocumentationStatusAPIResult,
    setCallUpdateDocumentationStatusAPIResult,
  ] = useState(true);

  const [documentationNotesLoading, setDocumentationNotesLoading] =
    useState(true);
  const [documentationStatusLoading, setDocumentationStatusLoading] =
    useState(true);

  const [displayFlashbarNotes, setDisplayFlashbarNotes] = useState(false);
  const [displayFlashbarStatus, setDisplayFlashbarStatus] = useState(false);

  const writerId = v4();
  const writerItem = generateFlashBarItem(writerId);
  writerItem.onDismiss = () =>
    setWriterItems((items) => items.filter((item) => item.id !== writerId));
  writerItem.type = 'success';
  writerItem.content = 'updating writer alias';
  writerItem.loading = true;

  const statusId = v4();
  const statusItem = generateFlashBarItem(statusId);
  statusItem.onDismiss = () =>
    setStatusItems((items) => items.filter((item) => item.id !== statusId));
  statusItem.type = 'success';
  statusItem.content = 'updating documentation status';
  statusItem.loading = true;

  const notesId = v4();
  const notesItem = generateFlashBarItem(notesId);
  notesItem.onDismiss = () =>
    setNotesItems((items) => items.filter((item) => item.id !== notesId));
  notesItem.type = 'success';
  notesItem.content = 'updating documentation notes';
  notesItem.loading = true;

  const [notesItems, setNotesItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([statusItem]);

  const [writerItems, setWriterItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([writerItem]);

  const [statusItems, setStatusItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([statusItem]);

  useEffect(() => {
    const newWriterItems = [...writerItems];

    if (!writerLoading) {
      if (callUpdateWriterAPIResult) {
        writerItem.content = 'update writer success';
      } else {
        writerItem.type = 'error';
        writerItem.content = 'failed to update writer name';
      }
      writerItem.loading = false;
    }
    newWriterItems[0] = writerItem;

    setWriterItems(newWriterItems);
  }, [, callUpdateWriterAPIResult, writerLoading]);

  useEffect(() => {
    const newStatusItems = [...statusItems];

    if (!documentationStatusLoading) {
      if (callUpdateDocumentationStatusAPIResult) {
        statusItem.content = 'update documentation status success';
      } else {
        statusItem.type = 'error';
        statusItem.content = 'failed to update documentation status';
      }
      statusItem.loading = false;
    }
    newStatusItems[0] = statusItem;

    setStatusItems(newStatusItems);
  }, [callUpdateDocumentationStatusAPIResult, documentationStatusLoading]);

  useEffect(() => {
    const newNotesItems = [...notesItems];

    if (!documentationNotesLoading) {
      if (callUpdateDocumentationNotesAPIResult) {
        notesItem.content = 'update documentation notes success';
      } else {
        notesItem.type = 'error';
        notesItem.content = 'failed to update documentation notes';
      }
      notesItem.loading = false;
    }
    newNotesItems[0] = notesItem;

    setNotesItems(newNotesItems);
  }, [callUpdateDocumentationNotesAPIResult, documentationNotesLoading]);

  useEffect(() => {
    setwriterAliasCache(task?.writerAssignment?.alias);
    setwriterFullNameCache(task?.writerAssignment?.fullName);

    setWriterAlias(task?.writerAssignment?.alias);
    setWriterFullName(task?.writerAssignment?.fullName);

    setSelectedDocumentationStatusString({
      label: task?.documentationStatus
        ? TraslatedDocumentStatus[task?.documentationStatus].toString()
        : options[0].label,
      value: task?.documentationStatus
        ? task?.documentationStatus.toString()
        : options[0].value,
    });
    setDocumentationNotes(task?.documentationNotes);
  }, [task]);

  async function makeApiCall(requestBody: UpdateWriterAssignmentCommandInput) {
    if (!token) throw new NoTokenError();
    try {
      const response: UpdateWriterAssignmentCommandOutput = await callApi(
        new UpdateWriterAssignmentCommand({
          launchId: requestBody.launchId,
          writerAssignment: requestBody.writerAssignment,
        }),
        token
      );
      setWriterLoading(false);
      if (response.$metadata.httpStatusCode == 200) {
        setCallUpdateWriterAPIResult(true);
      }
    } catch (err) {
      setWriterLoading(false);
      setCallUpdateWriterAPIResult(false);
    }
  }

  async function callUpdateDocumentationNotesAPI() {
    if (!token) throw new NoTokenError();
    try {
      const result: UpdateDocumentationNotesCommandOutput = await callApi(
        new UpdateDocumentationNotesCommand({
          launchId: task.launchId,
          documentationNotes: documentationNotes ?? '',
        }),
        token
      );
      setDocumentationNotesLoading(false);
      if (result.$metadata.httpStatusCode == 200) {
        setCallUpdateDocumentationNotesAPIResult(true);
      }
    } catch (err) {
      setDocumentationNotesLoading(false);
      setCallUpdateDocumentationNotesAPIResult(false);
    }
  }

  async function callUpdateDocumentationStatusAPI() {
    if (!token) throw new NoTokenError();

    try {
      const result: UpdateDocumentationStatusCommandOutput = await callApi(
        new UpdateDocumentationStatusCommand({
          launchId: task.launchId,
          documentationStatus:
            DocumentationStatus[
              selectedDocumentationStatusString.value as keyof typeof DocumentationStatus
            ],
        }),
        token
      );
      setDocumentationStatusLoading(false);
      if (result.$metadata.httpStatusCode == 200) {
        setCallUpdateDocumentationStatusAPIResult(true);
      }
    } catch (err) {
      setDocumentationStatusLoading(false);
      setCallUpdateDocumentationStatusAPIResult(false);
    }
  }

  useEffect(() => {
    if (writerAlias) {
      const allLetters = /^[A-Za-z0-9]+$/;
      const invalidChars = /[@#$%^&*]/;
      if (!allLetters.exec(writerAlias) || invalidChars.exec(writerAlias)) {
        setErrorIndicator('Alias Can only take letters');
      } else if (writerAlias.includes(' ')) {
        setErrorIndicator('Alias Cannot Have Space in Between');
      } else {
        setErrorIndicator('');
      }
    }
  }, [writerAlias]);

  return (
    <div>
      <SpaceBetween direction='vertical' size='l'>
        <SpaceBetween direction='vertical' size='xs'>
          <Box display={displayFlashbarWriter ? 'block' : 'none'}>
            <Flashbar items={writerItems} />
          </Box>
          <Box display={displayFlashbarStatus ? 'block' : 'none'}>
            <Flashbar items={statusItems} />
          </Box>
          <Box display={displayFlashbarNotes ? 'block' : 'none'}>
            <Flashbar items={notesItems} />
          </Box>
        </SpaceBetween>

        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction='horizontal' size='m'>
                  <Button
                    variant='primary'
                    disabled={
                      !task?.allowedActions?.includes('WRITE') ||
                      errorIndicator.length > 0
                    }
                    onClick={() => {
                      editWriterEnabled ? setwriterAliasCache(writerAlias) : 0;
                      editWriterEnabled
                        ? setwriterFullNameCache(writerFullName)
                        : 0;
                      setEditWriterEnabled(editWriterEnabled ? false : true);

                      if (editWriterEnabled) {
                        const requestBody: UpdateWriterAssignmentCommandInput =
                          {
                            launchId: task.launchId,
                            writerAssignment: {
                              alias: writerAlias ?? '',
                            },
                          };
                        setDisplayFlashbarWriter(true);
                        setWriterLoading(true);
                        makeApiCall(requestBody);
                      }
                    }}
                  >
                    {`${editWriterEnabled ? 'Save' : 'Edit'}`}
                  </Button>
                  <Box display={`${editWriterEnabled ? 'block' : 'none'}`}>
                    <Button
                      onClick={() => {
                        setEditWriterEnabled(false);
                        setWriterAlias(writerAliasCache);
                        setWriterFullName(writerFullNameCache);
                        setErrorIndicator('');
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </SpaceBetween>
              }
            >
              Assigned writer
            </Header>
          }
        >
          <FormField
            // description='alias of the assigned writer. TLC automatically assigns are writer based on data in virtual smiley. If the information below is incorrect, please reassign to the correct dependencies.'
            label='Assigned Writer Alias'
            errorText={errorIndicator}
          >
            <Autosuggest
              readOnly={!editWriterEnabled}
              disabled={!editWriterEnabled}
              onChange={({ detail }) => {
                setWriterAlias(detail.value);
                detail.value ? 0 : setErrorIndicator('field cannot be empty!');
              }}
              value={writerAlias ? writerAlias : ''}
              options={autoSuggestions}
              loadingText='Loading alias'
              empty='No matches found'
              virtualScroll
            />
          </FormField>
        </Container>
        <Container>
          <SpaceBetween direction='vertical' size='l'>
            <FormField label='Documentation Status'></FormField>
            <Select
              selectedOption={selectedDocumentationStatusString}
              onChange={({ detail }) => {
                setSelectedDocumentationStatusString({
                  label: detail.selectedOption.label!,
                  value: detail.selectedOption.value!,
                });
                //setSelectedDocumentationStatus(DocumentationStatus[selectedDocumentationStatusString.value as keyof typeof DocumentationStatus])
              }}
              options={options}
            />
            <SpaceBetween direction='horizontal' size='l'>
              <Button
                variant='primary'
                disabled={task?.workStatus === WorkStatus.DELETED}
                onClick={() => {
                  setDocumentationStatusLoading(true);
                  callUpdateDocumentationStatusAPI();
                  setDisplayFlashbarStatus(true);
                }}
              >
                Save Status
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
        <Container>
          <SpaceBetween direction='vertical' size='l'>
            <FormField label='Documentation Notes'></FormField>
            <Textarea
              value={documentationNotes ?? ''}
              onChange={({ detail }) => {
                setDocumentationNotes(detail.value);
              }}
            />
            <SpaceBetween direction='horizontal' size='l'>
              <Button
                variant='primary'
                disabled={task?.workStatus === WorkStatus.DELETED}
                onClick={() => {
                  setDocumentationNotesLoading(true);
                  callUpdateDocumentationNotesAPI();
                  setDisplayFlashbarNotes(true);
                }}
              >
                Save Notes
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </div>
  );
};

export default EditWriterSection;
