import { PackageInstructions } from './PackageInstructions';
import React, { useEffect, useState } from 'react';
import { Button, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { WorkStatus } from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { ProtoPBI } from './ProtoPBI';

type PackagesHeaderProps = {
  polling: boolean;
  onAddPackageClick: () => void;
  reject: () => void;
  onSubmitClick: () => void;
  endpoint?: string;
  onPublishClick: () => void;
  workStatus: WorkStatus;
  items: ProtoPBI[];
};
export const PackagesHeader = (props: PackagesHeaderProps) => {
  const [canSubmit, setCanSubmit] = useState(false);

  const [canAddPackage, setCanAddPackage] = useState(false);
  const [approved, setApproved] = useState(false);

  const [manifestBuilt, setManifestBuilt] = useState(
    props.workStatus === WorkStatus.MANIFEST_BUILT
  );
  const [taskReceived, setTaskReceived] = useState(false);
  useEffect(() => {
    setManifestBuilt(props.workStatus === WorkStatus.MANIFEST_BUILT);
    setApproved(props.workStatus === WorkStatus.REVIEW_APPROVED);
    setTaskReceived(props.workStatus === WorkStatus.TASK_RECEIVED);
  }, [props.workStatus]);
  useEffect(() => {
    setCanSubmit(taskReceived);
  }, [props.items, taskReceived]);

  useEffect(() => setCanAddPackage(taskReceived), [taskReceived]);
  return (
    <>
      <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
        <PackageInstructions />
        {(!props.polling || approved || manifestBuilt) && (
          <SpaceBetween alignItems={'end'} size={'m'} direction={'horizontal'}>
            {canAddPackage && (
              <Button variant='normal' onClick={props.onAddPackageClick}>
                Add package
              </Button>
            )}
            {(canSubmit || canAddPackage) && (
              <Button
                variant='primary'
                onClick={props.onSubmitClick}
                disabled={!canSubmit}
              >
                Submit
              </Button>
            )}
            {(manifestBuilt || approved) && (
              <>
                <Button variant='normal' onClick={props.reject}>
                  Update
                </Button>
                <Button target='_blank' variant='normal' href={props.endpoint}>
                  Preview
                </Button>
                <Button
                  variant='primary'
                  onClick={props.onPublishClick}
                  disabled={approved}
                >
                  Ready to Publish
                </Button>
              </>
            )}
          </SpaceBetween>
        )}
      </Grid>
    </>
  );
};
