import { TranslatedWorkStatus } from '../../../interfaces/index';
import {
  LaunchTask,
  WorkStatus,
  ResolveLaunchTaskCommand,
  ResolveLaunchTaskCommandInput,
  ResolveLaunchTaskCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react/polaris';
import { BuddyLaunches } from './BuddyLaunches';
import { WriterSection } from './writerSection';
import { DetailSection } from './conferenceDetailSection';
import { EditorView } from './editorViewSection';
import { Packages } from './PackagesEditor/Packages';
import { convertToPST } from '../utils';
import { useLocation } from 'react-router-dom';
import { BuildStatusBadge } from './BuildStatusBadge';
import { callApi } from '../../../client';
import { NoTokenError } from '../../../errors/NoTokenError';
import { useAuthState } from '../../../authentication/context';
//import { AssignedReaders } from '../ui/readerSection';
import AdvanceErrorHandlingPage from '../../AdvancedErrorPage';

interface LaunchTaskProps {
  launchTask?: LaunchTask;
  id: string;
  detailLoading: boolean;
  detailFetchStatus: number;
  errorMsg: string;
  buddyLaunchesLoading: boolean;
  buddyLaunches: LaunchTask[];
  buddyLaunchFetchSuccess: boolean;
}

export const TaskDetail = ({
  id,
  launchTask,
  detailLoading,
  detailFetchStatus,
  errorMsg,
  buddyLaunches,
  buddyLaunchesLoading,
  buddyLaunchFetchSuccess,
}: LaunchTaskProps) => {
  const [children, setChildren] = useState(0);
  const { token } = useAuthState();
  const [descriptionText, setDescriptionText] = useState('');

  const [packages, setPackages] = useState(
    launchTask?.packageBuildInfoList ?? []
  );

  const currentLocation = useLocation();

  const [buddyLaunchesList, setBuddyLaunchesList] = useState(buddyLaunches);

  const [approved, setApproved] = useState(false);
  const [manifestBuilt, setManifestBuilt] = useState(false);

  const [publish, setPublish] = useState(false);

  const [workStatus, setWorkStatus] = useState(launchTask?.workStatus);

  useEffect(() => {
    setWorkStatus(launchTask?.workStatus);
  }, [launchTask?.workStatus]);

  useEffect(() => {
    setManifestBuilt(workStatus === WorkStatus.MANIFEST_BUILT);
    setApproved(workStatus === WorkStatus.REVIEW_APPROVED);
  }, [workStatus]);

  useEffect(() => {
    setBuddyLaunchesList(buddyLaunches);
    if (launchTask) {
      if (launchTask.packageBuildInfoList) {
        setPackages(launchTask.packageBuildInfoList);
      }
    }

    if (launchTask && launchTask.childTask) {
      setChildren(launchTask.childTask.length);
    }

    let description = '';

    description += 'Last Modified: ';
    if (launchTask?.modifiedAt) {
      description += convertToPST(launchTask?.modifiedAt, true);
    }

    description += '.   Last Modified by: ';
    if (launchTask?.modifiedBy) {
      description += launchTask?.modifiedBy + '.   ';
    }

    setDescriptionText(description);
  }, [launchTask, buddyLaunches, location.pathname]);

  if (detailLoading) {
    return <Spinner size='large' />;
  }

  if (Math.floor(detailFetchStatus / 100) !== 2 || !launchTask) {
    return (
      <AdvanceErrorHandlingPage
        status={detailFetchStatus}
        errorMsg={errorMsg}
      />
    );
  }

  async function ResolveTaskApi(input: ResolveLaunchTaskCommandInput) {
    if (!token) throw new NoTokenError();

    try {
      let response: ResolveLaunchTaskCommandOutput = await callApi(
        new ResolveLaunchTaskCommand(input),
        token
      );

      if (response.$metadata.httpStatusCode == 200) {
      } else {
        console.error(
          'Failed to resolve task, status code:',
          response.$metadata.httpStatusCode
        );
      }
    } catch (err: any) {
      console.log('error with ResolveTaskApi', err);
    }
  }

  return (
    <Box>
      <Container
        disableContentPaddings
        header={
          <Header
            actions={
              <SpaceBetween
                direction='horizontal'
                size='l'
                alignItems={'center'}
              >
                <Badge color='green'>
                  Status:{' '}
                  {launchTask?.workStatus
                    ? TranslatedWorkStatus[launchTask.workStatus]
                    : '-'}
                </Badge>
                {launchTask?.workStatus === WorkStatus.PUBLISH_SCHEDULED &&
                launchTask?.allowedActions?.includes('WRITE') &&
                launchTask?.targetDocLiveTime instanceof Date &&
                launchTask.targetDocLiveTime < new Date() ? (
                  <Button
                    onClick={() => {
                      ResolveTaskApi({ launchId: launchTask.launchId }),
                        location.reload();
                    }}
                  >
                    Resolve Task
                  </Button>
                ) : null}
                <TextContent>
                  <p>
                    <strong>
                      Due Date:{' '}
                      {launchTask?.dueDate
                        ? convertToPST(launchTask?.dueDate, false)
                        : '-'}
                    </strong>
                  </p>
                </TextContent>
              </SpaceBetween>
            }
            description={descriptionText}
          >
            Launch task detail
          </Header>
        }
      />
      <Container
        disableHeaderPaddings
        header={
          <div style={{ paddingLeft: '20px' }}>
            <Header
              variant='h2'
              actions={
                launchTask.parentTask ? (
                  <div style={{ paddingRight: '20px' }}>
                    <Badge color='blue'>This is a buddy launch task</Badge>
                  </div>
                ) : (
                  <></>
                )
              }
            >
              {launchTask.parentTask ? 'Parent task - ' : ''}
              <Link href={`#/conferences/view-${launchTask.parentTask}`}>
                {launchTask.parentTask}
              </Link>
            </Header>
          </div>
        }
      >
        <SpaceBetween direction='vertical' size='l'>
          <DetailSection
            id={id}
            loading={detailLoading}
            launchTask={launchTask}
          />

          <EditorView launchTaskDetail={launchTask} />

          <WriterSection task={launchTask} />

          <ExpandableSection
            variant='container'
            defaultExpanded={true}
            headerText={`Package build information list (${
              launchTask.packageBuildInfoList?.length ?? 0
            })`}
            headerActions={
              <SpaceBetween
                size={'m'}
                direction={'horizontal'}
                alignItems={'center'}
              >
                {launchTask.buildRequestStatus && (
                  <BuildStatusBadge status={launchTask.buildRequestStatus} />
                )}
                {(approved || manifestBuilt) && (
                  <>
                    <Button
                      target='_blank'
                      variant='normal'
                      href={launchTask.reviewEndpoint}
                    >
                      Preview
                    </Button>
                    <Button
                      variant='normal'
                      onClick={() => setPublish(true)}
                      disabled={approved}
                    >
                      Ready to Publish
                    </Button>
                  </>
                )}
                {launchTask.workStatus !== WorkStatus.DELETED && (
                  <Button
                    variant='primary'
                    onClick={() =>
                      (location.href = `/#/conferences/view-${launchTask.launchId.toString()}/packages`)
                    }
                  >
                    Edit
                  </Button>
                )}
              </SpaceBetween>
            }
          >
            <Box display={packages.length ? 'block' : 'none'}>
              <Packages
                task={launchTask}
                onPoll={setWorkStatus}
                publish={publish}
                onPublishing={() => setPublish(false)}
              />
            </Box>
          </ExpandableSection>

          <Box display={launchTask.parentTask ? 'none' : 'block'}>
            <ExpandableSection
              variant='container'
              defaultExpanded={true}
              headerText={`Buddy launch information (${
                buddyLaunchesList.length ?? 'loading...'
              })`}
              headerDescription='Use this section to add launch ID for a buddy launch. A buddy launch item for another service to support your launch. For API Reference documentation, please provide all package dependencies.'
              headerActions={
                <Button
                  variant='primary'
                  disabled={launchTask.workStatus === WorkStatus.DELETED}
                  href={`#${currentLocation.pathname}/buddylaunches`}
                >
                  Edit
                </Button>
              }
            >
              <Box display={buddyLaunchesList.length > 0 ? 'block' : 'none'}>
                {buddyLaunchesList && (
                  <BuddyLaunches
                    launchTaskDetail={launchTask}
                    buddyLaunches={buddyLaunchesList}
                    onCountChange={setChildren}
                    buddyLaunchesLoading={buddyLaunchesLoading}
                    buddyLaunchFetchSuccess={buddyLaunchFetchSuccess}
                  />
                )}
              </Box>
            </ExpandableSection>
          </Box>
        </SpaceBetween>
      </Container>
    </Box>
  );
};

export default TaskDetail;
