import {
  FormField,
  Input,
  Box,
  Container,
  Header,
  SpaceBetween,
  Button,
  Form,
  ColumnLayout,
  Spinner,
  Modal,
  Badge,
  Flashbar,
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris';
import { useEffect, useState } from 'react';
import {
  LaunchTask,
  WorkStatus,
  UserInfo,
  DeleteLaunchTaskCommand,
  DeleteLaunchTaskCommandOutput,
  CreateLaunchInput,
  CreateLaunchTaskCommandOutput,
  CreateLaunchTaskCommand,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { useAuthState } from '../../../authentication/context';
import { callApi } from '../../../client';
import { NoTokenError } from '../../../errors/NoTokenError';

import { v4 } from 'uuid';
import { generateFlashBarItem } from '../ui/editEditorViewSection';

interface BuddyLaunchesProps {
  buddyLaunches: LaunchTask[];
  launchTaskDetail: LaunchTask;
  buddyLaunchesLoading: boolean;
  buddyLaunchFetchSuccess: boolean;
}

export const EditBuddyLaunchPage = ({
  launchTaskDetail,
  buddyLaunches,
  buddyLaunchesLoading,
  buddyLaunchFetchSuccess,
}: BuddyLaunchesProps) => {
  const [buddyLaunchName, setBuddyLaunchName] = useState('');
  const [buddyLaunchWriter, setBuddyLaunchWriter] = useState('');

  const [buddyLaunchesTaskList, setBuddyLaunchesTaskList] =
    useState<LaunchTask[]>(buddyLaunches);

  const [newEntryLoading, setNewEntryLoading] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(true);

  const [deleteLoading, setDeleteLoading] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);

  const [enableDeleteButton, setEnableDeleteButton] = useState(true);
  const [deleteTaskName, setDeleteTaskName] = useState('');

  const { isAuthenticated, token, packageInfoPermissionsGroup } =
    useAuthState();

  const [disableAddBuddyLaunchButton, setDisableAddBuddyLaunchButton] =
    useState(false);

  const [writerSectionErrorIndicator, setWriterSectionErrorIndicator] =
    useState('');
  const [taskNameSectionErrorIndicator, setTaskNameSectionErrorIndicator] =
    useState('');

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [errorMsg, SetErrorMsg] = useState('');

  const [submitFlashbarVisible, setSubmitFlashbarVisible] = useState(false);
  const [deleteFlashbarVisible, setDeleteFlashbarVisible] = useState(false);

  const addItemId = v4();
  const addItem = generateFlashBarItem(addItemId);
  addItem.onDismiss = () =>
    setItems((items) => items.filter((item) => item.id !== addItemId));
  addItem.type = 'success';
  addItem.content = 'updating new buddy launch';
  addItem.loading = true;

  const deleteItemId = v4();
  const deleteItem = generateFlashBarItem(deleteItemId);
  deleteItem.onDismiss = () =>
    setDeleteItems((items) => items.filter((item) => item.id !== deleteItemId));
  deleteItem.type = 'success';
  deleteItem.content = 'deleting buddy launch';
  deleteItem.loading = true;

  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([
    addItem,
  ]);
  const [deleteItems, setDeleteItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([deleteItem]);

  const [selectedTask, setSelectedTask] = useState<LaunchTask>();

  const addBuddy = (task: LaunchTask) => {
    setBuddyLaunchesTaskList([...buddyLaunchesTaskList, task]);
  };

  const deleteBuddy = (buddyLaunch: LaunchTask) => {
    const updatedItems = [...buddyLaunchesTaskList].filter(
      (task) => task !== buddyLaunch
    );
    setBuddyLaunchesTaskList(updatedItems);
  };

  async function callDeleteLaunchTaskApi(buddyLaunch?: LaunchTask) {
    if (!buddyLaunch || !buddyLaunch.launchId) {
      setDeleteLoading(false);
      setEnableDeleteButton(true);
      setDeleteSuccess(false);
      return;
    }
    if (!token) throw new NoTokenError();

    try {
      const response: DeleteLaunchTaskCommandOutput = await callApi(
        new DeleteLaunchTaskCommand({
          launchId: buddyLaunch.launchId,
        }),
        token
      );
      setDeleteLoading(false);
      setEnableDeleteButton(true);
      setDeleteTaskName(buddyLaunch.launchId + ' ' + buddyLaunch.name);
      if (response.$metadata.httpStatusCode == 200) {
        deleteBuddy(buddyLaunch);
        setDeleteSuccess(true);
      }
    } catch (err) {
      setDeleteLoading(false);
      setEnableDeleteButton(true);
      setDeleteTaskName(buddyLaunch.launchId + ' ' + buddyLaunch.name);
      setDeleteSuccess(false);
    }
  }

  async function callCreateLaunchTaskApi(newLaunchTask: CreateLaunchInput) {
    if (!token) throw new NoTokenError();
    try {
      const response: CreateLaunchTaskCommandOutput = await callApi(
        new CreateLaunchTaskCommand({
          task: newLaunchTask,
        }),
        token
      );
      setNewEntryLoading(false);
      setFetchSuccess(true);

      addBuddy(response.task);
    } catch (err) {
      setNewEntryLoading(false);
      setFetchSuccess(false);
      console.log('error with creating buddy launch', err);
    }
  }

  useEffect(() => {
    setBuddyLaunchesTaskList(buddyLaunches);
  }, [buddyLaunches]);

  useEffect(() => {
    const newItems = [...items];
    const newDeleteItems = [...deleteItems];

    if (!newEntryLoading) {
      addItem.loading = false;

      if (fetchSuccess) {
        addItem.type = 'success';
        addItem.content = 'new buddy launch update complete';
      } else {
        addItem.content = 'update new buddy launch failed';
        addItem.type = 'error';
      }
    } else {
      addItem.content = 'updating new buddy launch';
      addItem.loading = true;
      addItem.type = 'success';
    }

    if (!deleteLoading) {
      deleteItem.loading = false;

      if (deleteSuccess) {
        deleteItem.type = 'success';
        deleteItem.content = 'delete buddy launch complete';
      } else {
        deleteItem.content = 'delete buddy launch failed';
        deleteItem.type = 'error';
      }
    } else {
      deleteItem.content = 'deleting buddy launch';
      deleteItem.loading = true;
      deleteItem.type = 'success';
    }

    newItems[0] = addItem;
    newDeleteItems[0] = deleteItem;

    setItems(newItems);
    setDeleteItems(newDeleteItems);
  }, [newEntryLoading, fetchSuccess, deleteLoading, deleteSuccess]);

  useEffect(() => {
    if (buddyLaunchWriter) {
      const allLetters = /^[A-Za-z0-9]+$/;
      const invalidChars = /[@#$%^&*]/;
      if (
        !allLetters.exec(buddyLaunchWriter) ||
        invalidChars.exec(buddyLaunchWriter)
      ) {
        setWriterSectionErrorIndicator('Alias can only take letters or number');
      } else if (buddyLaunchWriter.includes(' ')) {
        setWriterSectionErrorIndicator('Alias cannot have space in between');
      } else {
        setWriterSectionErrorIndicator('');
      }
    } else {
      setWriterSectionErrorIndicator('');
    }
  }, [buddyLaunchWriter]);

  useEffect(() => {
    if (buddyLaunchName) {
      const invalidChars = /[@#/*]/;
      if (invalidChars.exec(buddyLaunchName)) {
        setTaskNameSectionErrorIndicator(
          'Please do not include @#/* in task name!'
        );
      } else if (buddyLaunchWriter.includes(' ')) {
        setTaskNameSectionErrorIndicator(
          'Please do not include space in task name!'
        );
      } else if (
        buddyLaunches.find((task) => {
          return task.name === buddyLaunchName;
        })
      ) {
        setTaskNameSectionErrorIndicator('Duplicate task name!');
      } else {
        setTaskNameSectionErrorIndicator('');
      }
    } else {
      setTaskNameSectionErrorIndicator('');
    }
  }, [buddyLaunchName]);

  useEffect(() => {
    if (!buddyLaunchName || !buddyLaunchWriter) {
      setDisableAddBuddyLaunchButton(true);
    } else {
      setDisableAddBuddyLaunchButton(false);
    }
  }, [buddyLaunchWriter, buddyLaunchName]);

  if (!buddyLaunchFetchSuccess) {
    return <Badge color='red'>Error</Badge>;
  }

  if (buddyLaunchesLoading) {
    return <Spinner size='large' />;
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <SpaceBetween direction='vertical' size='l'>
        <Box display={submitFlashbarVisible ? 'block' : 'none'}>
          <Flashbar items={items} />
        </Box>
        <Box display={deleteFlashbarVisible ? 'block' : 'none'}>
          <Flashbar items={deleteItems} />
        </Box>
        <Form>
          <Container header={<Header variant='h2'>Buddy Launches</Header>}>
            <SpaceBetween direction='vertical' size='l'>
              <Box display='block'>
                {buddyLaunchesTaskList.map((buddyLaunch, i) => (
                  <div key={buddyLaunch.name}>
                    <Container>
                      <Box display='block'>
                        <ColumnLayout columns={4}>
                          <div>
                            <FormField
                              label='Buddy Launch ID'
                              errorText={errorMsg}
                            >
                              <Input
                                disabled={true}
                                value={buddyLaunch.launchId}
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField label='Buddy Launch Name'>
                              <Input disabled={true} value={buddyLaunch.name} />
                            </FormField>
                          </div>
                          <div>
                            <FormField label='Assigned Writer'>
                              <Input
                                disabled={true}
                                value={
                                  buddyLaunch?.writerAssignment?.alias ?? ''
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField label='Action'>
                              <Button
                                iconName='status-negative'
                                variant='link'
                                disabled={
                                  !enableDeleteButton ||
                                  launchTaskDetail.workStatus ===
                                    WorkStatus.DELETED
                                }
                                onClick={() => {
                                  setDeleteModalVisible(true);
                                  setSelectedTask(buddyLaunch);
                                }}
                              >
                                Remove
                              </Button>
                            </FormField>
                          </div>
                        </ColumnLayout>
                      </Box>
                    </Container>
                  </div>
                ))}
              </Box>

              <Modal
                size='medium'
                onDismiss={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                footer={
                  <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                      <Button
                        variant='link'
                        onClick={() => setDeleteModalVisible(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='primary'
                        onClick={() => {
                          setDeleteFlashbarVisible(true);
                          setDeleteModalVisible(false);
                          setEnableDeleteButton(false);
                          setDeleteLoading(true);
                          callDeleteLaunchTaskApi(selectedTask);
                        }}
                      >
                        Confirm
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
              >
                Do you want to delete this task
                <Badge color='red'>{` ${selectedTask?.name}`}</Badge>
              </Modal>

              <Box display={newEntryLoading ? 'block' : 'none'}>
                <Spinner size='large' />
              </Box>

              <Box display={newEntryLoading ? 'none' : 'block'}>
                <Container>
                  <ColumnLayout columns={3}>
                    <div>
                      <FormField
                        label='Buddy launch name'
                        errorText={taskNameSectionErrorIndicator}
                      >
                        <Input
                          disabled={false}
                          value={buddyLaunchName}
                          onChange={({ detail }) => {
                            setBuddyLaunchName(detail.value);
                          }}
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label='Assigned writer'
                        errorText={writerSectionErrorIndicator}
                      >
                        <Input
                          disabled={false}
                          value={buddyLaunchWriter}
                          onChange={({ detail }) => {
                            setBuddyLaunchWriter(detail.value);
                          }}
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField label='Action'>
                        <Button
                          variant='primary'
                          disabled={
                            disableAddBuddyLaunchButton ||
                            writerSectionErrorIndicator !== '' ||
                            taskNameSectionErrorIndicator !== '' ||
                            launchTaskDetail.workStatus === WorkStatus.DELETED
                          }
                          onClick={() => {
                            if (buddyLaunchName && buddyLaunchWriter) {
                              const newWriter: UserInfo = {
                                alias: buddyLaunchWriter,
                                fullName: '',
                              };
                              const newLaunchTask: CreateLaunchInput = {
                                name: buddyLaunchName,
                                parentTask: launchTaskDetail.launchId,
                                writerAssignment: newWriter,
                                status: WorkStatus.TASK_RECEIVED,
                                type: 'OTHER',
                                documentationStatus: 'GREEN',
                              };
                              setSubmitFlashbarVisible(true);
                              setNewEntryLoading(true);
                              callCreateLaunchTaskApi(newLaunchTask);
                            }
                            setBuddyLaunchName('');
                            setBuddyLaunchWriter('');
                            setNewEntryLoading(true);
                          }}
                        >
                          Add
                        </Button>
                      </FormField>
                    </div>
                  </ColumnLayout>
                </Container>
              </Box>
            </SpaceBetween>
          </Container>
        </Form>
      </SpaceBetween>
    </form>
  );
};

export default EditBuddyLaunchPage;
