import React from 'react';
import { Alert, Box, Link } from '@amzn/awsui-components-react';
import { Slackbox } from './Slackbox';

interface WarningProps {
  message: string;
  header: string;
}

export const Warning: React.FC<WarningProps> = ({ message, header }) => (
  <Alert statusIconAriaLabel='Warning' type='warning' header={header}>
    <Box variant='p'>{message}</Box>
    <Slackbox />
  </Alert>
);
